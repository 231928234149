let luxonRef
try {
    luxonRef = require('luxon');
} catch (e) {
    luxonRef = luxon
}
const DateTime = luxonRef.DateTime;


/**
 * Class to manage date related requirements
 */
class DateCore {
    static DateTime = DateTime

    /**
     * Returns a generic date when there is an error with date processing
     *
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} A generic date
     */
    static #errorDate(jsDate=true) {
        return DateCore.getDate(jsDate)
    }

    /**
     * Returns the current date
     *
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The current date
     */
    static getDate(jsDate=true) {
        if (jsDate) {
            return DateTime.local().toJSDate();
        }
        return DateTime.local();
    }

    /**
     * Attempts to parse a date from multiple different forms
     *
     * @deprecated
     * @param date Date to attempt to parse
     * @param jsDate Whether the date should be converted to JS Date
     */
    static parseDate(date, jsDate=true) {
        // TODO
    }

    /**
     * Converts a JS Date to a DateTime
     *
     * @param date The JS Date to convert
     * @returns {DateTime} The DateTime object parsed from the JS Date
     */
    static getDateFromJSDate(date) {
        if (date instanceof Date) {
            return DateTime.fromJSDate(date);
        }

        console.error("DateCore.getDateFromJSDate: date is not instance of Date")
        return DateCore.#errorDate();
    }

    /**
     * Converts a ISO date string to a DateTime or Date
     *
     * @param date ISO string to convert
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date parsed from the ISO string
     */
    static getDateFromISOString(date, jsDate=true) {
        if (DateTime.isDateTime(date)) {
            return date;
        }

        if (jsDate) {
            try {
                return DateTime.fromISO(date).toJSDate();
            } catch (error) {
                console.error("DateCore.getDateFromISOString: date cannot be converted")
                return DateCore.#errorDate(jsDate);
            }
        }

        try {
            return DateTime.fromISO(date);
        } catch (e) {
            console.error("DateCore.getDateFromISOString: date cannot be converted")
            return DateCore.#errorDate(jsDate);
        }
    }

    /**
     * Converts a string to a DateTime or Date using the given format
     *
     * @param dateString The string to convert
     * @param format The format the date string is in
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date parsed from the string using the given format
     */
    static getDateFromFormat(dateString, format, jsDate=true) {
        if (typeof dateString !== "string") {
            console.error(`DateCore.getDateFromInputFormat: dateString is not a string, it is a ${typeof dateString}`)
            return DateCore.#errorDate(jsDate);
        }

        if (jsDate) {
            try {
                return DateTime.fromFormat(dateString, format).toJSDate();
            } catch (e) {
                console.error("DateCore.getDateFromInputFormat: dateString cannot be converted")
                return DateCore.#errorDate(jsDate);
            }
        }

        try {
            return DateTime.fromFormat(dateString, format);
        } catch (e) {
            console.error("DateCore.getDateFromInputFormat: dateString cannot be converted")
            return DateCore.#errorDate(jsDate);
        }
    }

    /**
     * Converts a string in the format of a date input to a DateTime or Date
     *
     * @param dateString The date input string to convert
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date parsed from the date input string
     */
    static getDateFromInputFormat(dateString, jsDate=true) {
        return DateCore.getDateFromFormat(dateString, "yyyy-MM-dd", jsDate);
    }

    /**
     * Converts a string in the format of a URL param/query to a DateTime or Date
     *
     * @param dateString The URL param/query string to convert
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date parsed from the URL string
     */
    static getDateFromURLFormat(dateString, jsDate=true) {
        return DateCore.getDateFromFormat(dateString, "M-d-yyyy", jsDate)
    }

    /**
     * Returns the current date as a DateTime
     * Mainly used for dateInterpreters
     *
     * @param date Date to pass through
     * @returns {DateTime} DateTime object passed through
     */
    static getDateFromDateTime(date) {
        if (DateTime.isDateTime(date)) {
            return date
        }

        console.error("DateCore.getDateFromDateTime: date is not instance of Date")
        return DateCore.#errorDate(false);
    }

    /**
     * Parses a database date string into a DateTime object.
     *
     * @deprecated
     * @param dateString The custom date string to parse.
     * @param jsDate Whether the output should be converted to JS Date.
     * @returns {DateTime|Date} The parsed DateTime object or JS Date.
     */
    static getDateFromDatabaseFormat(dateString, jsDate = true) {
        const format = "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZ (z)";
        try {
            const dateTime = DateTime.fromFormat(dateString, format);
            if (!dateTime.isValid) {
                console.error("DateCore.getDateFromDatabaseFormat: Invalid date string");
                return DateCore.#errorDate(jsDate);
            }
            return jsDate ? dateTime.toJSDate() : dateTime;
        } catch (e) {
            console.error("DateCore.getDateFromDatabaseFormat: Error parsing date string", e);
            return DateCore.#errorDate(jsDate);
        }
    }

    /**
     * Adjusts a date by a specified number of days
     *
     * @param date Date to adjust
     * @param numberOfDays The number of days to adjust the date by
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date adjusted by the specified number of days
     */
    static adjustDate(date, numberOfDays, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        if (jsDate) {
            if (date === undefined) {
                return DateCore.getDate(false).plus({days: numberOfDays}).toJSDate();
            } else {
                try {
                    return dateInterpreter(date).plus({days: numberOfDays}).toJSDate();
                } catch (e) {
                    console.error("DateCore.getDateFromInputFormat: dateString cannot be converted")
                    return DateCore.#errorDate(jsDate);
                }
            }
        }

        if (date === undefined) {
            return DateCore.getDate(false).plus({days: numberOfDays});
        } else {
            try {
                return dateInterpreter(date).plus({days: numberOfDays});
            } catch (e) {
                console.error("DateCore.getDateFromInputFormat: dateString cannot be converted")
                return DateCore.#errorDate(jsDate);
            }
        }
    }

    /**
     * Returns the date of the previous day
     *
     * @param date Date to find the previous day of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date of the previous day
     */
    static getYesterday(date=undefined, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        return DateCore.adjustDate(date, -1, dateInterpreter, jsDate);
    }

    /**
     * Returns the date of the next day
     *
     * @param date Date to find the next day of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date of the next day
     */
    static getTomorrow(date=undefined,  dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        return DateCore.adjustDate(date, 1, dateInterpreter, jsDate);
    }

    /**
     * Returns the day number of the date
     *
     * @param date Date to find the day number of. If left undefined the current date is used
     * @returns {Number} The day number of the date
     */
    static getDay(date=undefined) {
        if (!date) {
            date = DateCore.getDate();
        }

        if (!DateTime.isDateTime(date)) {
            console.error("DateCore.getDay: date is not instance of DateTime")
            return -1;
        }

        return date.getDay();
    }

    /**
     * Finds the beginning of the week of the date
     *
     * @param date Date to find the beginning of the week of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date of the beginning of the week
     */
    static getBeginningOfWeek(date=undefined, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        if (date === undefined) {
            date = DateCore.getDate();
        } else {
            date = dateInterpreter(date, false)
        }

        if (!DateTime.isDateTime(date)) {
            console.error("DateCore.getBeginningOfWeek: date is not instance of DateTime")
            return DateCore.#errorDate(jsDate);
        }

        if (jsDate) {
            return date.startOf('week').toJSDate();
        }
        return date.startOf('week');
    }

    /**
     * Finds the end of the week of the date
     *
     * @param date Date to find the end of the week of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date of the end of the week
     */
    static getEndOfWeek(date=undefined, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        if (date === undefined) {
            date = DateCore.getDate();
        } else {
            date = dateInterpreter(date, false)
        }

        if (!DateTime.isDateTime(date)) {
            console.error("DateCore.getBeginningOfWeek: date is not instance of DateTime")
            return DateCore.#errorDate(jsDate);
        }

        if (jsDate) {
            return date.endOf('week').toJSDate();
        }
        return date.endOf('week');
    }

    /**
     * Returns the NEG number for the date
     *
     * @param date Date to find the NEG number of. If left undefined the current date is used
     * @returns {string} The NEG number for the date (Excluding the NEG prefix)
     */
    static getNEG(date=undefined) {
        if (!date) {
            date = DateCore.getDate();
        }

        // TODO
        return date.getDay();
    }

    /**
     * Returns the date at midnight
     *
     * @param date Date to find the date at midnight of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date at the midnight
     */
    static getDateAtMidnight(date=undefined, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        if (date !== undefined) {
            date = dateInterpreter(date, false)
        } else {
            date = DateCore.getDate(false)
        }

        if (!DateTime.isDateTime(date)) {
            console.error("DateCore.getDateAtMidnight: date is not instance of DateTime")
            return DateCore.#errorDate(jsDate);
        }

        if (jsDate) {
            return date.startOf('day').toJSDate();
        }

        return date.startOf('day');
    }

    /**
     * Returns the date at UTC (absolute) midnight
     *
     * @param date Date to find the date at midnight of. If left undefined the current date is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @param jsDate Whether the date should be converted to JS Date
     * @returns {DateTime|Date} The DateTime object or Date at the midnight
     */
    static toUTCMidnight(date, dateInterpreter=DateCore.getDateFromISOString, jsDate=true) {
        if (date !== undefined) {
            date = dateInterpreter(date, false)
        } else {
            date = DateCore.getDate(false)
        }

        if (!DateTime.isDateTime(date)) {
            console.error("DateCore.toUTCMidnight: date is not instance of DateTime")
            return DateCore.#errorDate(jsDate);
        }

        if (jsDate) {
            return date.toUTC().startOf('day').toJSDate();
        }

        return date.toUTC().startOf('day');
    }

    /**
     * Returns the number of minutes since midnight
     *
     * @param date Date/Time to find the number of minutes since midnight of. If left undefined the current date/time is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @returns {Number} The number of minutes since midnight
     */
    static getMinutesSinceMidnight(date=undefined, dateInterpreter=DateCore.getDateFromISOString) {
        if (date === undefined) {
            date = DateCore.getDate(false )
        } else {
            date = dateInterpreter(date, false)
        }

        const hours = date.hour;
        const minutes = date.minute;
        return (hours * 60) + minutes;
    }

    /**
     * Returns the number of days since the beginning of the year
     *
     * @param date Date/Time to find the number of days since the beginning of the year of. If left undefined the current date/time is used
     * @param dateInterpreter The interpreter to use to convert the date into a DateTime
     * @returns {Number} The number of days since the beginning of the year
     */
    static getJulianDate(date, dateInterpreter=DateCore.getDateFromISOString) {
        if (!date) {
            date = DateCore.getDate(false);
        } else {
            date = dateInterpreter(date, false)
        }

        return date.ordinal
    }
}


// Export the DateCore class
try {
    module.exports = DateCore
} catch (ReferenceError) {

}
